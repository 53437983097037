import { IPxValuation } from '../interfaces/PxWidget';

/**
 * Method returns px valuation after it's being processed in order to be displayed in px widget
 * @param valuationJSON
 * NOTE: tested
 */
export function processValuation(valuationJSON: any): IPxValuation {
    const {
        Valuation,
        CarDetails: { regNumber, makeName, modelName },
        bonusAmount,
        statusId,
    }: any = JSON.parse(valuationJSON);

    return {
        statusId,
        price: Valuation.estimateValuationApplied,
        licensePlate: regNumber,
        makeName,
        modelName,
        bonusAmount,
    };
}

/**
 * Method returns px valuation after it's being processed in order to be displayed in px widget
 * @param mopData
 * NOTE: tested
 */
export function processMopData(mopData: any): IPxValuation {
    const parsedCarDetailsMopData = mopData?.mopData ? JSON.parse(mopData?.mopData) : null;

    return {
        id: mopData?.id,
        statusId: mopData?.statusId,
        price: mopData?.valuation?.engagementValuationApplied,
        licensePlate: mopData?.carDetails?.regNumber,
        makeName: mopData?.carDetails?.makeName,
        modelName: mopData?.carDetails?.modelName,
        bonusAmount: mopData?.valuation?.bonusAmount,
        externalReferenceId: mopData?.externalReferenceId,
        vin: parsedCarDetailsMopData?.object?.CarDetails?.vin,
        registrationDate: parsedCarDetailsMopData?.object?.CarDetails?.firstRegistration,
        overallMilleage: parsedCarDetailsMopData?.object?.CarDetails?.CarState?.overallMileage,
    };
}

/**
 * Method returns true if simple valuation has data on widgetData
 * @param widgetData
 * NOTE: tested
 */
export function noSimpleValuation(widgetData: any): boolean {
    if (widgetData) {
        return !widgetData.simpleValuation || (widgetData.simpleValuation && !widgetData.simpleValuation.price);
    }

    return false;
}

export function isEmptyObject(obj: object): boolean {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function getDeviceTypeByWidthOfBrowser(): string {
    const MOBILE_BREAKPOINT: number = 480;
    const TABLET_BREAKPOINT: number = 768;

    if (window?.innerWidth < MOBILE_BREAKPOINT) { return 'mobile'; }
    if (window?.innerWidth >= MOBILE_BREAKPOINT && window?.innerWidth < TABLET_BREAKPOINT) { return 'tablet'; }

    return 'desktop';
}
